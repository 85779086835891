.App {
  text-align: center;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.Hours {
  border:#282c34 1px solid;
  float: left;
  margin-top: 4vh;
}

.Hour {
  height: 10vh;
}

.Hours {
  width: 3vw;
  background-color: rgb(250, 217, 185);
}

.Stage, .Slot {
  width: 15vw;
}

.Menu {
  width: 93vw;
}

.Now {
  position: absolute;
  z-index: 10;
  background-color: rgb(250, 70, 22);
  height: 1vh;
  width: 100%;
}

.Stage, .StageName, .Slot {
  border:#282c34 1px solid;
}

.Stage {
  vertical-align: top;
  top:0;
  height: 105vh;
  float: left;
}

.StageName {
  vertical-align: top;
  position: sticky;
  top:0;
  background-color:  rgb(255,180,108);
  z-index: 20;
}

.Slot {
  position: absolute;
}

.Times {
  font-size: x-small;
  vertical-align: bottom;
}

.MenuItem {
  display: inline-block;
  margin: 3px;
  background-color: rgb(250, 217, 185);
  color:rgb(250, 70, 22);
  padding: 5px;
  border-radius: 5px;
}

.Today {
  position: absolute;
  top: 0.5vh;
  left: 40vw;
  margin: auto;
  font-size: large;
  font-weight: bold;

}
.rank1 {
  background-color: rgb(255,180,108);
}

.rank2 {
  background-color: rgb(255,132,32);
}

.tigers_navy {
  background-color: rgb(12, 35, 64);
}

.tigers_orange {
  background-color: rgb(250, 70, 22);
}

